html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #f3f3f3;
  overflow: hidden;
}

.auth-container {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  width: 100vw;
  padding: 0px;
  left: 0px;
}

.auth-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  z-index: -1; /* Set z-index to -1 to place it behind the content */
}

.auth-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 20px;
  position: relative;
  z-index: 102;
  animation: fadeIn 2s;
  width: 300px;
  margin-left: auto;
  margin-right: 3vw; /* Füge das Margin rechts hinzu */
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.auth-card {
  width: 100%;
}

.form-control {
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.description {
  justify-content: right;
  padding: 2vw;
  color: #000;
  max-width: 400px;
  animation: slideIn 4s;
  text-align: left;
}

/* Media Queries */

@media (max-width: 767px) { /* Smartphone, wie iPhone XR */
  html, body {
    overflow-x: hidden;
  }

  .auth-container {
    align-items: center !important;
    max-width: 100%;
    overflow-x: hidden;
    display: flex !important;
    height: 100vh;
    position: relative !important;
    width: 100vw;
    padding:0px;
    left:0px;
    flex-direction: column; /* Ändere die Flex-Direktion zu "column" */
  }

  .auth-card-container {
    width: 95%; /* Verkleinere die Breite auf 90% */
    margin: 20px auto; /* Zentriere den Container */
  }

  .description {
    padding: 5vw;
    padding-top: 40vw;
    max-width: none; /* Setze die maximale Breite auf "none" */
  }
  
  .auth-container::before {
    height: 120vh !important;
    background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    background-size: auto;
  }
}

