

@keyframes rocketMove {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.congratulationAnimation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeButton {
    margin-top: 20px;  /* Dies sorgt dafür, dass der Button unter dem Text steht */
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    color: white;
    font-size: 24px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.closeButton:hover {
    background-color: rgba(255, 255, 255, 0.5);
}




.rocketAnimation {
    animation: rocketMove 1s infinite alternate;
}

.congratulationAnimation {
    text-align: center;
    color: white;
    animation: congratulationFade 2s forwards;
}
